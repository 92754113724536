import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useExperiments,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from 'wix-ui-tpa';
import settingsParams from '../settingsParams';
import { st, classes } from './Widget.st.css';

import { io, Socket } from 'socket.io-client';

const sampleRate = 16000;

const getMediaStream = () =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: 'default',
      sampleRate,
      sampleSize: 16,
      channelCount: 1,
    },
    video: false,
  });

type RecognizedData = {
  alternatives: { transcript: string }[];
};

export type ControllerProps = {
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const { t } = useTranslation();

  const [host, setHost] = useState<string>(
    'duplexer.wix.com/allen-speech/speech-to-text',
  );
  const [path, setPath] = useState<string>(
    '/allen-speech/speech-to-text/socket.io',
  );
  const [connection, setConnection] = useState<Socket>();
  const [recognized, setRecognized] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recorder, setRecorder] = useState<any>();

  const processorRef = useRef<any>();
  const audioContextRef = useRef<any>();
  const audioInputRef = useRef<any>();

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.id) {
      case 'host':
        setHost(e.target.value);
        break;
      case 'path':
        setPath(e.target.value);
        break;
    }
  }, []);

  const connect = useCallback(() => {
    connection?.disconnect();
    const socket = io(host, {
      path: path || undefined,
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('connected', socket.id);
      setConnection(socket);
    });

    socket.emit('start-audio-stream');

    socket.on('recognized-text-chunk', (data: RecognizedData[]) => {
      if (!data.length) {
        return;
      }

      setRecognized(
        data.map(({ alternatives }) => alternatives[0].transcript).join('\n'),
      );
    });

    socket.on('disconnect', () => {
      console.log('disconnected', socket.id);
    });
  }, [connection, host, path]);

  const disconnect = useCallback(() => {
    if (!connection) {
      return;
    }
    connection?.emit('end-audio-stream');
    connection?.disconnect();
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    audioContextRef.current?.close();
    setConnection(undefined);
    setRecorder(undefined);
    setIsRecording(false);
  }, [connection]);

  useEffect(() => {
    (async () => {
      if (connection) {
        if (isRecording) {
          return;
        }

        const stream = await getMediaStream();

        audioContextRef.current = new window.AudioContext();

        await audioContextRef.current.audioWorklet.addModule(
          'https://static.parastorage.com/services/recorder-worklet-processor/1.7.0/index.js',
        );

        audioContextRef.current.resume();

        audioInputRef.current =
          audioContextRef.current.createMediaStreamSource(stream);

        processorRef.current = new AudioWorkletNode(
          audioContextRef.current,
          'recorder.worklet',
        );

        processorRef.current.connect(audioContextRef.current.destination);
        audioContextRef.current.resume();

        audioInputRef.current.connect(processorRef.current);

        processorRef.current.port.onmessage = (event: { data: unknown }) => {
          const audioData = event.data;
          connection.emit('audio-stream-chunk', audioData);
        };
        setIsRecording(true);
      } else {
        console.error('No connection');
      }
    })();

    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== 'closed') {
          audioContextRef.current?.close();
        }
      }
    };
  }, [connection, isRecording, recorder]);

  return (
    <div>
      <div>
        <h1>Connection Config</h1>
        <div style={{ margin: '20px' }}>
          <div style={{ margin: '10px' }}>
            <label htmlFor="host">Host:</label>
          </div>
          <input
            id="host"
            value={host}
            placeholder="host name"
            onChange={onInputChange}
          />
        </div>
        <div style={{ margin: '20px' }}>
          <div style={{ margin: '10px' }}>
            <label htmlFor="path">Path:</label>
          </div>
          <input
            id="path"
            value={path}
            placeholder="path"
            onChange={onInputChange}
          />
        </div>
      </div>
      <Button
        data-hook="SttTest-button"
        onClick={isRecording ? disconnect : connect}
      >
        {isRecording ? 'Stop' : t('app.widget.start')}
      </Button>
      <div style={{ fontSize: '16px' }}>
        <p>connection: {connection ? 'connected' : 'disconnected'}</p>
        <p>Recognized: {recognized}</p>
      </div>
    </div>
  );
};

export default Widget;
