(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["SttTestViewerWidget"] = factory(require("react"), require("lodash"));
	else
		root["SttTestViewerWidget"] = factory(root["React"], root["_"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__7762__, __WEBPACK_EXTERNAL_MODULE__7607__) {
return 